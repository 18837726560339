import React from 'react';

function App() {
  return(
    <div className='card'>
      <img src="/logo.png" width={"300px"}></img>
      <p className='text'>
        Welcome to the TuxEasy!<br/>
        Your container is ready to use.<br/>
        You just need to upload your<br/>
        <code>docker-compose.yml</code><br/>
        and we will handle stuff.<br/>
      </p>
      <a className="btn-neomorph" href='https://tuxtech.com.tr' target='_blank'>
          Learn more 
          {/* Right Chevron */}
          <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-right" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <polyline points="9 6 15 12 9 18" />
          </svg>
        </a>
    </div>
  );
}

export default App;
